<template>
	<div class="page_comsxlisttable" style="width: 100%;height: 100%;">
		<el-dialog :title="dialogTitle" :append-to-body="true" :visible.sync="dialogVisible_zcList" width="1200px"
			custom-class="width_800 cus_dialog" @close="onClose">


			<el-row style="margin-bottom: 10px;width: 100%;" class="">
				<div class="flexBetween">
					<el-input size="mini" v-model="searchForm.title" clearable placeholder="名称"
						style="margin-right: 10px;"></el-input>
					<el-input size="mini" v-model="searchForm.xinghao" clearable placeholder="型号"
						style="margin-right: 10px;"></el-input>
					<el-input size="mini" v-model="searchForm.guige" clearable placeholder="规格"
						style="margin-right: 10px;"></el-input>
					<el-input size="mini" v-model="searchForm.bgr" clearable placeholder="使用人"
						style="margin-right: 10px;"></el-input>
				</div>
				<div class="flexBetween" style="margin-top: 10px;">
					<el-cascader clearable ref="myCascader" v-model="searchForm.zc_storeid" :options="areaList" size="mini"
						:props="{value: 'id', label: 'storename', emitPath: false,checkStrictly: true}"
						:show-all-levels="true" filterable clearable placeholder="请选择存放地"
						style="width: 100%;margin-right: 10px;"></el-cascader>
					<!-- <el-date-picker v-model="searchForm.delivery_date" type="date" size="mini" value-format="yyyy-MM-dd"
						placeholder="验收日期" style="width: 100%;margin-right: 10px;">
					</el-date-picker> -->
					<el-select clearable size="small" style="width: 100%;margin-right: 10px;" v-model="searchForm.term_id" filterable placeholder="学期" 
						>
						<el-option v-for="(item, index) in termList" :key="index" :label="item.title"
							:value="item.id"></el-option>
					</el-select>
					
					
					
					<el-input clearable size="mini" v-model="searchForm.checker" clearable placeholder="验收人"
						style="margin-right: 10px;"></el-input>
					<el-select clearable size="mini" v-model="searchForm.is_over_year" clearable placeholder="使用是否超过一年"
						style="width: 100%;">
						<el-option label="是" :value="1"></el-option>
						<el-option label="否" :value="0"></el-option>
					</el-select>
				</div>
				<div class="flex_1" style="margin-top: 10px;">
					<el-button size="mini" type="primary" icon="el-icon-search" @click="getList">查询</el-button>
					<el-button size="mini" type="primary" plain @click="searchForm = {}">重置</el-button>
				</div>

			</el-row>
			<el-table :data="DataList" height="calc( 100% - 100px)" style="width: 100%;" stripe border size="small"
				@selection-change="handleSelectionChange">
				<!--      <el-table-column label="操作" width="60" fixed="left">-->
				<!--        <template slot-scope="scope">-->
				<!--          <el-tag size="mini" style="cursor: pointer;" @click="chooseHC(scope.row)">选择</el-tag>-->

				<!--        </template>-->
				<!--      </el-table-column>-->
				<el-table-column type="selection" width="55"></el-table-column>

				<el-table-column type="index" label="编号" width="80"></el-table-column>
				<el-table-column prop="title" label="耗材名称" min-width="120" />

				<el-table-column type="expand" width="120">
					<template slot="header">
						详情
					</template>
					<template slot-scope="scope">
						<el-form label-position="left" inline class="demo-table-expand" style="margin: 20px 0">
							<div style="color: #2459f3;font-size: 14px;font-weight: 700;">耗材信息</div>
							<el-form-item label="耗材名称:">{{ scope.row.title || '-'}}</el-form-item>
							<!-- <el-form-item label="耗材分类:">{{ scope.row.zc_typeid || '-'}}</el-form-item> -->
							<el-form-item label="品牌:">{{ scope.row.pinpai || '-'}}</el-form-item>
							<el-form-item label="型号:">{{ scope.row.xinghao || '-'}}</el-form-item>
							<!-- <el-form-item label="计量单位:">{{ scope.row.jldw || '-'}}</el-form-item> -->
							<el-form-item label="所在位置:">{{ scope.row.storename || '-'}}</el-form-item>
							<!--            <el-form-item label="购置方式:">{{ scope.row.zc_gztype || '-'}}</el-form-item>-->
							<!--            <el-form-item label="购置日期:">{{ scope.row.zc_date || '-'}}</el-form-item>-->
							<el-form-item :label="zd.name+':'" v-for="(zd,idx) in scope.row.zdjson"
								:key="idx">{{zd.value|| '-'}}</el-form-item>
							<!--            <el-form-item label="单价(元):">{{ scope.row.jine || '-' }}</el-form-item>-->

							<el-form-item label="数量:">{{ scope.row.store_num || '-'}}</el-form-item>

							<!--            <el-form-item label="预警值:">{{ scope.row.yujing_num || '-' }}</el-form-item>-->
							<el-row class="special">
								<el-form-item style="width: 100%;"
									label="描述说明:">{{ scope.row.zcdesc || '-' }}</el-form-item>
							</el-row>

							<div style="color: #2459f3;font-size: 14px;font-weight: 700;margin-top: 20px;">供应商信息</div>
							<el-form-item label="供应商:">{{ scope.row.wb_gxy || '-'}}</el-form-item>
							<el-form-item label="联系人:">{{ scope.row.wb_lxr || '-'}}</el-form-item>
							<el-form-item label="联系电话:">{{ scope.row.wb_phone || '-'}}</el-form-item>
							<el-form-item label="保修结束日期:"
								label-width="auto">{{ scope.row.wb_deadline?scope.row.wb_deadline.substr(0,10): '-'}}</el-form-item>
							<el-row class="special">
								<el-form-item style="width: 100%;"
									label="备注:"><span>{{ scope.row.wb_desc || '-'}}</span></el-form-item>
							</el-row>
						</el-form>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="typename1" label="耗材分类" width="120" :show-overflow-tooltip="true"/> -->
				<!--      <el-table-column prop="pinpai" label="耗材品牌"></el-table-column>-->
				<el-table-column prop="xinghao" label="型号" :show-overflow-tooltip="true"></el-table-column>
				<el-table-column prop="guige" label="规格" :show-overflow-tooltip="true"></el-table-column>
				<el-table-column prop="storename" label="存放地" :show-overflow-tooltip="true"></el-table-column>
				<!--      <el-table-column prop="jine" label="单价" width="100" :show-overflow-tooltip="true">-->
				<!--        <template slot-scope="scope">-->
				<!--          <span>￥{{ scope.row.jine }}</span>-->
				<!--        </template>-->
				<!--      </el-table-column>-->


				<el-table-column prop="store_num" label="剩余库存" :show-overflow-tooltip="true"></el-table-column>
				<!--      <el-table-column prop="yujing_num" label="预警值" :show-overflow-tooltip="true">-->
				<!--        <template slot-scope="scope">-->
				<!--          <span style="color: #f00">{{ scope.row.yujing_num }}</span>-->
				<!--        </template>-->
				<!--      </el-table-column>-->
			</el-table>


			<el-pagination class="cus_page" @size-change="handleSizeChange" @current-change="paginate"
				:current-page.sync="page.current_page" :page-sizes="[10, 50, 100]" :page-size="page.per_page"
				layout="total,sizes, prev, pager, next" :total="page.count">
			</el-pagination>

			<div slot="footer">
				<el-button type="primary" @click="chooseHC" size="small">确任选择</el-button>
				<el-button type="primary" @click="dialogVisible_zcList = false" size="small" plain>关闭</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		props: ['dialogVisible_zcList', 'dialogTitle','isAll'],
		data() {
			return {
				searchForm: {},
				DataList: [],
				areaList: [],
				termList:[],
				multipleSelection: [],
				page: {
					current_page: 1,
					per_page: 50,
					count: 0,
					pageSizes: [10, 50, 100]
				},
				drawer: false,
			}
		},
		mounted() {
			this.getList();
			this.getArea();
			this.getTerm()
		},
		methods: {
			getTerm(){
				this.$http.post(`/api/t_sch_terms_list`).then(res => {
					if (res && res.data.data && res.data.data.length > 0) {
						this.termList = res.data.data
						
					}
				})
			},
			getList() {
				let _this = this
				let params = {
						...this.searchForm
					},
					arr = []
				params.page = _this.page.current_page ? _this.page.current_page : 1
				params.pagesize = _this.page.per_page ? _this.page.per_page : 50
        params.is_all = this.isAll ?? 0
				this.$http.post("/api/sx_hc_xx_list", params).then(res => {
					if (res.data.data && res.data.data.length > 0) {
						res.data.data.map(a => {
							if (a.remark) {
								try {
									a.zdjson = JSON.parse(a.remark)
								} catch (error) {
									a.zdjson = [{
										name: "备注",
										value: a.remark
									}]
								}
							}
							if (a.jine) {
								a.jine = parseFloat(a.jine).toFixed(2)
							}
						})
						setTimeout(function() {
							_this.DataList = res.data.data;
							console.log(131, _this.DataList)
						}, 50)
					} else {
						_this.DataList = [];
					}

					if (res.data && res.data.page) {
						this.page = res.data.page
						this.page_hc = res.data.page
					}
				})
			},

			onSearch() {
				this.page.current_page = 1
				this.getList()
			},

			chooseHC() {
				let _this = this
				let flag = 0
				if (_this.multipleSelection.length == 0) {
					_this.$message.warning('请选择至少一条耗材')
				} else {
					_this.multipleSelection.map(row => {
						flag += 1
						_this.$$parent(_this, 'chooseHC', row)
					})
					if (flag == _this.multipleSelection.length) {
						this.onClose()
					}
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
				// console.log(val)
			},
			onClose() {
				this.$$parent(this, 'dialogVisible_zcList', false)
			},
			handleSizeChange(val) {
				this.page.current_page = 1
				this.page.per_page = val
				this.getList();
			},
			paginate(val) {
				this.page.current_page = val
				this.getList();
			},
			getArea() {
				this.$http.post("/api/zc_store").then(res => {
					this.deleteEmptyGroup(res.data).then(result => {
						this.areaList = result
					})
				})
			},
			// 处理没有children的分组
			deleteEmptyGroup(treeData) {
				return new Promise(resolve => {
					function traversal(data) {
						data.map(item => {
							for (let info in item) {
								if (item['children']) {
									if (item['children'].length > 0) {
										traversal(item['children'])
									}
									if (item['children'].length == 0) {
										delete item['children']
									}
								}
							}
						})
					}
					traversal(treeData)
					resolve(treeData)
				})
			},
		}
	}
</script>

<style lang="less" type="text/less">
	.page_comsxlisttable {
  .el-table__expanded-cell {
    padding: 8px 8px 6px 68px;
  }

  .demo-table-expand {
    font-size: 14px;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
  .special .el-form-item{
    width: 100% !important;

  }
}
</style>